<script setup lang="ts">
defineProps<{
  policyId: string
}>()

const editor = useEditor()
</script>

<template>
  <button
    class="btn btn-icon"
    @click.prevent.stop="editor.togglePolicyPin(policyId)"
  >
    <div
      class="text-neutral-800"
      :class="{
        'i-ph-push-pin-simple hover:bg-secondary-400':
          !editor.policyIsPinned(policyId),
        'i-ph-push-pin-simple-fill text-secondary-600 hover:i-ph-push-pin-simple-slash-fill':
          editor.policyIsPinned(policyId),
      }"
    />
  </button>
</template>
